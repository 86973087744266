<style>
    @font-face {
        font-family: "UnidreamLED";
        src: url(../../../plugins/UnidreamLED/UnidreamLED.eot); /***兼容ie9***/
        src: url(../../../plugins/UnidreamLED/UnidreamLED.eot?#iefix) format("embedded-opentype"),
            /***兼容ie6-ie8***/ url("../../../plugins/UnidreamLED/UnidreamLED.woff") format("woff"),
        local("UnidreamLED"), url("../../../plugins/UnidreamLED/UnidreamLED.woff"); /***默认使用本地的***/
    }

    .led {
        font-family: "UnidreamLED";
        letter-spacing: 3px;
    }

    .bigData {
        background: url(./bigData/bg.png) no-repeat top center;
        background-size: 100% auto;
    }

    .bigData .title-top {
        line-height: 20px;
        padding: 12px 12px 0 12px;
        background: transparent;
        height: 50px;
    }

    .bigData .ant-tabs-bar {
        border-bottom: 1px solid #00eefb55;
        margin-bottom: 5px;
    }

    .bigData .ant-tabs-nav .ant-tabs-tab {
        color: #ffffff99;
        padding: 0 16px 12px 16px;
    }

    .bigData .ant-tabs-nav .ant-tabs-tab-active {
        color: #79f7f1;
    }

    .bigData .ant-tabs-ink-bar {
        background: #79f7f1;
    }

    .bigData .titlen {
        line-height: 58px;
        height: 58px;
        margin: 0;
        text-align: center;
        color: #79f7f1;
        background: url(./bigData/subtitle.png) no-repeat center center;
        background: auto 38px;
    }

    .bigData .total_bg {
        background: url(./bigData/total.png) no-repeat top center;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
</style>
<template>
    <video-bg id="bg" :sources="['http://hospital.yh-w.cn/video/bg2.mp4']">
        <a-layout class="bigData">
            <a-layout-header class="title-top">
                <h4 style="color:#ffffff00">
                    . <span style="float:right">{{tips}}</span>
                </h4>
                <img src="./bigData/title.png" style="width: 100%;position: relative;top: -30px"/>
            </a-layout-header>
            <a-layout style="padding:12px;background:transparent">
                <a-layout-sider width="440" style="background: transparent;padding:0">
                    <techCard title="动态血压报告生成情况">
                        <a-row :gutter="12" style="margin-bottom:20px;">
                            <a-col :span="6" style="text-align:center">
                                <img src="./bigData/icon1.png" style="width:60px">
                            </a-col>
                            <a-col :span="6">
                                <h1 style="text-align:center;color:#79F7F1;font-size:32px;line-height:44px;margin:0;font-weight:300"
                                    class="led">{{total.report.t}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    累计报告(份)</p>
                            </a-col>
                            <a-col :span="6">
                                <h1 style="text-align:center;color:#79F7F1;font-size:32px;line-height:44px;margin:0;font-weight:300"
                                    class="led">{{total.report.t_24}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    近24h总报告(份)</p>
                            </a-col>
                            <a-col :span="6">
                                <h1 style="text-align:center;color:#79F7F1;font-size:32px;line-height:44px;margin:0;font-weight:300"
                                    class="led">{{total.report.t_24_ok}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    近24h已生成(份)</p>
                            </a-col>
                        </a-row>
                        <h6 style="color:#79F7F1;background:#012C31;margin:0;padding:12px 5px">
                            <a-row :gutter="5">
                                <a-col :span="3" align="Center">姓名</a-col>
                                <a-col :span="6" align="Center">手机号</a-col>
                                <a-col :span="3" align="Center">设备</a-col>
                                <a-col :span="6" align="Center">报告状态</a-col>
                                <a-col :span="6">开始时间</a-col>
                            </a-row>
                        </h6>
                        <vue-seamless-scroll :data="total.report.list"
                                             style="overflow-y:hidden;height:250px;margin-top:20px"
                                             :class-option="classOption">

                            <h6 v-for="(item,index) in total.report.list" :key="'report'+(100+index)"
                                style="color:#79F7F1;margin:0;margin-top:15px">
                                <div @click="look_btn(item.id)">
                                    <a-row :gutter="5" style="font-size: 12px">
                                        <a-col :span="3" align="Center">{{item.name}}</a-col>
                                        <a-col :span="6" align="Center">{{item.mobile}}</a-col>
                                        <a-col :span="3" align="Center">{{item.note}}</a-col>
                                        <a-col :span="6" align="Center">{{item.is_24}}</a-col>
                                        <a-col :span="6">{{item.DT}}</a-col>
                                    </a-row>
                                </div>
                            </h6>
                        </vue-seamless-scroll>
                    </techCard>

                    <techCard title="动态血压设备使用情况">
                        <a-row :gutter="12" style="margin-bottom:20px;">
                            <a-col :span="6" style="text-align:center">
                                <img src="./bigData/icon2.png" style="width:60px">
                            </a-col>
                            <a-col :span="6">
                                <h1 style="text-align:center;color:#79F7F1;font-size:32px;line-height:44px;margin:0;font-weight:300"
                                    class="led">{{total.device.t}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    设备总数(台)</p>
                            </a-col>
                            <a-col :span="6">
                                <h1 style="text-align:center;color:#79F7F1;font-size:32px;line-height:44px;margin:0;font-weight:300"
                                    class="led">{{total.device.used}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    已绑定(台)</p>
                            </a-col>
                            <a-col :span="6">
                                <h1 style="text-align:center;color:#79F7F1;font-size:32px;line-height:44px;margin:0;font-weight:300"
                                    class="led">{{total.device.t-total.device.used}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    未绑定(台)</p>
                            </a-col>
                        </a-row>
                        <h6 style="color:#79F7F1;background:#012C31;margin:0;padding:12px 5px">
                            <a-row :gutter="5">
                                <a-col :span="9">设备序列号</a-col>
                                <a-col :span="6" align="Center">设备编号</a-col>
                                <a-col :span="3" align="Center">姓名</a-col>
                                <a-col :span="6" align="Center">手机号</a-col>
                            </a-row>
                        </h6>
                        <vue-seamless-scroll :data="total.device.list"
                                             style="overflow-y:hidden;height:250px;margin-top:20px"
                                             :class-option="classOption2">
                            <h6 v-for="(item,index) in total.device.list" :key="'device'+(100+index)"
                                style="color:#79F7F1;margin:0;margin-top:15px">
                                <div @click="goto_report_by_imei(item.imei)">
                                    <a-row :gutter="5" style="font-size: 12px">
                                        <a-col :span="9">{{item.imei}}</a-col>
                                        <a-col :span="6" align="Center">{{item.note}}</a-col>
                                        <a-col :span="3" align="Center">
                                            <template v-if="item.uid>0">{{item.name}}</template>
                                            <template v-else>未绑定</template>
                                        </a-col>
                                        <a-col :span="6" align="Center">
                                            <template v-if="item.uid>0">{{item.mobile}}</template>
                                            <template v-else>-</template>
                                        </a-col>
                                    </a-row>
                                </div>
                            </h6>
                        </vue-seamless-scroll>
                    </techCard>

                </a-layout-sider>
                <a-layout-content style="background:#060B1B88;padding:15px 44px">
                    <h4 class="titlen">动态血压实时监控</h4>
                    <a-row :gutter="12" style="position:relative;top:0px">
                        <a-col :offset="2" :span="6">
                            <techCard>
                                <h6 style="text-align:Center;color:#79f7f1">报告总数</h6>
                                <h4 style="text-align:Center;font-size:48px;margin:0;margin-top:10px;line-height:44px;color:#79F7F1"
                                    class="led">{{total.report.t0}}</h4>
                            </techCard>
                        </a-col>
                        <a-col :span="6">
                            <techCard>
                                <h6 style="text-align:Center;color:#79f7f1">设备总数</h6>
                                <h4 style="text-align:Center;font-size:48px;margin:0;margin-top:10px;line-height:44px;color:#FEB433"
                                    class="led">{{total.device.t0}}</h4>
                            </techCard>
                        </a-col>
                        <a-col :span="6">
                            <techCard>
                                <h6 style="text-align:Center;color:#79f7f1">患者总数</h6>
                                <h4 style="text-align:Center;font-size:48px;margin:0;margin-top:10px;line-height:44px;color:#9CEB36"
                                    class="led">{{total.user0}}</h4>
                            </techCard>
                        </a-col>
                    </a-row>

                    <techCard style="width:200px;position:relative;top:435px">
                        <a-row>
                            <a-col :span="6">
                                <i class="iconfont icon-xinshuai" style="color:#79f7f1;font-size:30px"></i>
                            </a-col>
                            <a-col :span="18">
                                <h5 style="color:#fff;margin:0">动态血压测量</h5>
                                <h6 style="color:#ffffffaa;margin:0">累计 <span style="font-size:20px;color:#79f7f1"
                                                                              class="led">{{total.c_t}}</span> 次</h6>
                                <h6 style="color:#ffffffaa;margin:0">今日 <span style="font-size:20px;color:#79f7f1"
                                                                              class="led">{{total.c_t_t}}</span> 次</h6>
                            </a-col>
                        </a-row>
                    </techCard>

                    <techCard style="width:200px;position:relative;top:435px">
                        <a-row>
                            <a-col :span="6">
                                <i class="iconfont icon-xieyang" style="color:#79f7f1;font-size:30px"></i>
                            </a-col>
                            <a-col :span="18">
                                <h5 style="color:#fff;margin:0">收缩压血压异常数</h5>
                                <h6 style="color:#ffffffaa;margin:0">累计 <span style="font-size:20px;color:#79f7f1"
                                                                              class="led">{{total.s_t}}</span> 次</h6>
                                <h6 style="color:#ffffffaa;margin:0">今日 <span style="font-size:20px;color:#79f7f1"
                                                                              class="led">{{total.s_t_t}}</span> 次</h6>
                            </a-col>
                        </a-row>
                    </techCard>
                    <techCard style="width:200px;position:relative;top:435px">
                        <a-row>
                            <a-col :span="6">
                                <i class="iconfont icon-xueya" style="color:#79f7f1;font-size:30px"></i>
                            </a-col>
                            <a-col :span="18">
                                <h5 style="color:#fff;margin:0">舒张压血压异常数</h5>
                                <h6 style="color:#ffffffaa;margin:0">累计 <span style="font-size:20px;color:#79f7f1"
                                                                              class="led">{{total.s_t}}</span> 次</h6>
                                <h6 style="color:#ffffffaa;margin:0">今日 <span style="font-size:20px;color:#79f7f1"
                                                                              class="led">{{total.s_t_t}}</span> 次</h6>
                            </a-col>
                        </a-row>
                    </techCard>
                </a-layout-content>
                <a-layout-sider width="440" style="background: transparent;padding:0">
                    <techCard title="患者总体情况">
                        <div class="total_bg" style="padding:0px 0 70px 0;margin-top:-40px">
                            <a-row>
                                <a-col :span="8">
                                    <h1 style="color:#79f7f1;font-size:32px;text-align:center;margin:200px 0 0 0"
                                        class="led">{{total.user_month}}</h1>
                                    <h4 style="color:#fff;text-align:center">本月新增患者</h4>
                                </a-col>
                                <a-col :span="8">
                                    <h1 style="color:#79f7f1;font-size:32px;text-align:center;margin:120px 0 0 0;"
                                        class="led">{{total.user}}</h1>
                                    <h4 style="color:#fff;text-align:center">总患者人数</h4>
                                </a-col>
                                <a-col :span="8">
                                    <h1 style="color:#79f7f1;font-size:32px;text-align:center;margin:200px 0 0 0"
                                        class="led">{{total.user_today}}</h1>
                                    <h4 style="color:#fff;text-align:center">今日新增患者</h4>
                                </a-col>
                            </a-row>
                        </div>

                    </techCard>
                    <techCard title="患者性别统计">
                        <div id="sex" style="height:200px"></div>
                    </techCard>
                    <techCard title="患者年龄分布">
                        <div id="birth" style="height:200px"></div>
                    </techCard>
                </a-layout-sider>
            </a-layout>
        </a-layout>
    </video-bg>
</template>
<script>
    import VideoBg from "vue-videobg";
    import request from "../../../plugins/axios";
    import techCard from "../../../components/data/techCard.vue";
    import {Pie, G2} from "@antv/g2plot";
    import {Column} from "@antv/g2plot";

    var G = null;
    var piePlot = null;
    var group = null;
    var birth = null;
    var is_set_birth = 0;
    var is_set_piePlot = 0;
    var timer = null;

    import vueSeamlessScroll from "vue-seamless-scroll";

    export default {
        name: "bigData",
        props: ["tips"],
        components: {
            techCard, //科技卡片
            VideoBg,
            vueSeamlessScroll,
        },
        data() {
            return {
                is_user: false,
                total: {
                    report: {
                        t: 0,
                        t_24: 0,
                        t_24_ok: 0,
                        list: [],
                    },
                    device: {
                        t: 0,
                        used: 0,
                        list: [],
                    },
                },
            };
        },
        computed: {
            classOption() {
                return {
                    step: 5, // 数值越大速度滚动越快
                    limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 32, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                };
            },
            classOption2() {
                return {
                    step: 5, // 数值越大速度滚动越快
                    limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 32, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                };
            },
        },
        mounted() {
            //
            let url = window.location.href
            console.log(url)
            let huid = this.getUrlParam('huid')

            this.get_total(huid);
            timer = setInterval(() => {
                this.get_total(huid);
            }, 10000);
        },
        methods: {
            getUrlParam(name) {
                let url = window.location.href;
                // 获取参数部分
                let params = url.split('?')[1];
                // 将参数部分转换为对象
                let paramsObj = {};
                if (params) {
                    let paramsArr = params.split('&');
                    for (let i = 0; i < paramsArr.length; i++) {
                        let param = paramsArr[i].split('=');
                        paramsObj[param[0]] = param[1];
                    }
                }
                return paramsObj[name];
            },
            goto_report_by_imei(imei) {
                this.$router.push({name: "/admin/report", params: {imei: imei}});
            },
            look_btn(id) {
                request({
                    url: "/hospital/Read/make_docx_v2",
                    data: {
                        id,
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        window.open(
                            "https://view.officeapps.live.com/op/view.aspx?src=" +
                            ret.data +
                            "&wdOrigin=BROWSELINK"
                        );
                    }
                });
            },
            get_total(huid) {
                request({
                    url: "/hospital/Screen/bigData",
                    data: {huid: huid},
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.total = ret.data;
                        if (is_set_piePlot == 0) {
                            piePlot = new Pie("sex", {
                                appendPadding: 10,
                                data: ret.data.sex,
                                angleField: "num",
                                colorField: "sex",
                                radius: 0.66,
                                color: ["#1890ff", "#f04864"],
                                label: {
                                    content: (obj) => {
                                        G = G2.getEngine("canvas");
                                        group = new G.Group({});
                                        group.addShape({
                                            type: "image",
                                            attrs: {
                                                x: 0,
                                                y: 0,
                                                width: 40,
                                                height: 50,
                                                img:
                                                    obj.sex === "男"
                                                        ? "https://gw.alipayobjects.com/zos/rmsportal/oeCxrAewtedMBYOETCln.png"
                                                        : "https://gw.alipayobjects.com/zos/rmsportal/mweUsJpBWucJRixSfWVP.png",

                                                // ? "http://hospital.yh-w.cn/video/sex1.png"
                                                // : "http://hospital.yh-w.cn/video/sex2.png",
                                            },
                                        });

                                        group.addShape({
                                            type: "text",
                                            attrs: {
                                                x: 20,
                                                y: 54,
                                                text: obj.sex,
                                                textAlign: "center",
                                                textBaseline: "top",
                                                fill: obj.sex === "男" ? "#1890ff" : "#f04864",
                                            },
                                        });
                                        group.addShape({
                                            type: "text",
                                            attrs: {
                                                x: 10,
                                                y: 84,
                                                text: `${obj.num}%`,
                                                fill: "rgba(255, 255, 255, 1)",
                                                fontWeight: 700,
                                            },
                                        });
                                        return group;
                                    },
                                },
                                interactions: [{type: "element-active"}],
                            });
                            piePlot.render();
                            is_set_piePlot = 1;
                        }
                        if (is_set_birth == 0) {
                            birth = new Column("birth", {
                                data: ret.data.birth,
                                xField: "birth",
                                yField: "num",
                                color: "l(270) 0:#1275EA  1:#79F7F1",
                                animation: {
                                    appear: {
                                        easing: "easeLinear",
                                        duration: 3000,
                                        delay: 0,
                                    },
                                },
                            });
                            birth.render();
                            is_set_birth = 1;
                        }
                    }
                });
            },
        },
    };
</script>
